var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table browser mb-0 no-border"},[_c('tbody',[_c('tr',[_vm._m(0),_c('td',{staticClass:"border-0"},[_vm._v("Google Chrome")]),_c('td',{staticClass:"border-0",attrs:{"align":"right"}},[_c('b-badge',{staticClass:"px-2",attrs:{"pill":"","variant":"info"}},[_vm._v("23%")])],1)]),_c('tr',[_vm._m(1),_c('td',{staticClass:"border-0"},[_vm._v("Mozila Firefox")]),_c('td',{staticClass:"border-0",attrs:{"align":"right"}},[_c('b-badge',{staticClass:"px-2",attrs:{"pill":"","variant":"success"}},[_vm._v("15%")])],1)]),_c('tr',[_vm._m(2),_c('td',{staticClass:"border-0"},[_vm._v("Apple Safari")]),_c('td',{staticClass:"border-0",attrs:{"align":"right"}},[_c('b-badge',{staticClass:"px-2",attrs:{"pill":"","variant":"primary"}},[_vm._v("07%")])],1)]),_c('tr',[_vm._m(3),_c('td',{staticClass:"border-0"},[_vm._v("Internet Explorer")]),_c('td',{staticClass:"border-0",attrs:{"align":"right"}},[_c('b-badge',{staticClass:"px-2",attrs:{"pill":"","variant":"warning"}},[_vm._v("09%")])],1)]),_c('tr',[_vm._m(4),_c('td',{staticClass:"border-0"},[_vm._v("Opera mini")]),_c('td',{staticClass:"border-0",attrs:{"align":"right"}},[_c('b-badge',{staticClass:"px-2",attrs:{"pill":"","variant":"danger"}},[_vm._v("23%")])],1)]),_c('tr',[_vm._m(5),_c('td',{staticClass:"border-0"},[_vm._v("Microsoft edge")]),_c('td',{staticClass:"border-0",attrs:{"align":"right"}},[_c('b-badge',{staticClass:"px-2",attrs:{"pill":"","variant":"primary"}},[_vm._v("09%")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"border-0",staticStyle:{"width":"40px"}},[_c('img',{attrs:{"src":require("@/assets/images/browser/chrome-logo.png"),"alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"border-0"},[_c('img',{attrs:{"src":require("@/assets/images/browser/firefox-logo.png"),"alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"border-0"},[_c('img',{attrs:{"src":require("@/assets/images/browser/safari-logo.png"),"alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"border-0"},[_c('img',{attrs:{"src":require("@/assets/images/browser/internet-logo.png"),"alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"border-0"},[_c('img',{attrs:{"src":require("@/assets/images/browser/opera-logo.png"),"alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"border-0"},[_c('img',{attrs:{"src":require("@/assets/images/browser/internet-logo.png"),"alt":"logo"}})])
}]

export { render, staticRenderFns }